<template>
  <div v-if="option">
    <ModalEditImage
      v-if="showEditImage"
      :showModal="showEditImage"
      :onClickCancel="hideModal"
    />

    <ui-section-header
      :showEdit="true"
      :editFunction="setShowEditImage"
      :editLabel="'Edit'"
    >
      <template v-slot:title>Image</template>
    </ui-section-header>

    <Message :message="'Image explanation'" />

    <ImageViewer
      class="is-4by3"
      type="location"
      :imageSrc="option.Image"
      :itemName="option.Name"
      :itemId="option.Location.LocationId"
      :imageSize="640"
      :aspectRatio="4/3"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

const Message = () => import(/* webpackChunkName: "option-images" */ '@/components/UI/Message')
const ModalEditImage = () => import(/* webpackChunkName: "option-images" */ '@/components/Options/ModalEditOptionImage')
const ImageViewer = () => import(/* webpackChunkName: "option-images" */ '@/components/UI/Components/ImageViewer')

export default {
  components: {
    Message,
    ModalEditImage,
    ImageViewer
  },

  data() {
    return {
      showEditImage: false,
    }
  },

  computed: {
    ...mapState('optionStore', ['option']),
  },

  methods: {
    setShowEditImage() {
      this.showEditImage = true
    },

    hideModal() {
      this.showEditImage = false
    },
  },
}
</script>

<style></style>
